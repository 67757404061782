<template>
  <f7-page class="explore-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <HomeNavigationComponent search="pjf" @searched="onSearch" @changeType="changeType" />

      <f7-fab v-if="$h.isUserLogin()" position="center-bottom" href="/post/create/">
        <font-awesome-icon :icon="['far', 'plus']" />
      </f7-fab>

      <div v-if="shopLinkBanners && shopLinkBanners.length > 0" class="link-container">
        <template v-for="linkBanner in shopLinkBanners" :key="'sbl_' + linkBanner.BannerId">
          <a @click="onViewBanner(linkBanner)">
            <img :src="$h.getImage(linkBanner?.Image, 'BANNER', null, false)" alt="Shop Banner Link Image" loading="lazy" />
          </a>
        </template>
      </div>
    </template>

    <Suspense>
      <FullWidthBannerListComponent type="HOMEPAGE" divider="true" />
    </Suspense>

    <section class="post-container pjf-post-list">
      <Suspense>
        <PostListComponent ref="postListComponent" :mode="mode" :size="16" :isElastic="isElastic" />
      </Suspense>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, defineAsyncComponent } from 'vue'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers.js'
import { get } from '@/utils/axios'
import { configs, extendedConfigs } from '@/utils/configs.js'

// import HomeNavigationComponent from '@/components/navigations/HomeNavigationComponent.vue'
// import PostListComponent from '@/components/PostListComponent.vue'
// import FullWidthBannerListComponent from '@/components/FullWidthBannerListComponent.vue'

export default defineComponent({
  name: 'ExplorePJFPage',
  components: {
    HomeNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "home-navigation" */ /* webpackMode: "lazy" */ '@/components/navigations/HomeNavigationComponent.vue')),
    PostListComponent: defineAsyncComponent(() => import(/* webpackChunkName: "post-list" */ /* webpackMode: "lazy" */ '@/components/PostListComponent.vue')),
    FullWidthBannerListComponent: defineAsyncComponent(() => import(/* webpackChunkName: "full-width" */ /* webpackMode: "lazy" */ '@/components/FullWidthBannerListComponent.vue'))
  },
  props: { f7router: Object },
  setup(props) {
    const store = useStore()
    const root = ref(null)
    const postListComponent = ref(false)
    const showPreloader = ref(false)
    const mode = ref('')

    const shopLinkBanners = ref([])
    const isElastic = ref(false)

    onMounted(async () => {
      if (extendedConfigs?.isElastic) {
        isElastic.value = true
      }
      await getBannerList()
    })

    const getBannerList = async () => {
      let ret = await get('/banner/list', { Type: 'SHOPLINK', page: 1, size: 6 })

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]
          shopLinkBanners.value.push(item)
        }
      }
    }

    const goToPageName = (pageName) => {
      props.f7router.navigate({ name: pageName })
    }

    const onViewBanner = (banner) => {
      if (banner.Link) {
        if (banner.Link.indexOf('http') > -1) {
          helpers.deepLinking(props.f7router, banner.Link)
        } else {
          if (banner.Link.indexOf('/') > -1) {
            props.f7router.navigate(banner.Link)
          } else {
            props.f7router.navigate({ name: banner.Link })
          }
        }
      }
    }

    return {
      postListComponent,
      showPreloader,
      mode,
      goToPageName,
      shopLinkBanners,
      onViewBanner,
      isElastic
    }
  },
  methods: {
    async loadMore() {
      this.showPreloader = true
      await this.$refs.postListComponent.loadMore()
      this.showPreloader = false
    },
    onSearch(searchValue) {
      this.$refs.postListComponent.search(searchValue)
    },
    changeType(val) {
      let mode = ''
      if (val == 'ALL') {
        val = ''
      } else {
        mode = 'pjf'
      }
      this.$refs.postListComponent.changeType(mode)
    }
  }
})
</script>

<style scoped>
.hidden {
  overflow: hidden;
}
.bg-gray {
  width: 100%;
  min-height: 735px;
}
</style>
